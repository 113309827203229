import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm, ValidationError } from '@formspree/react';


const FormContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  border: 2px solid #FF69B4;
  border-radius: 10px;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 2.5rem;
  color: #FF69B4;
  margin-bottom: 1rem;
`;

const Subtitle = styled.h2`
  text-align: center;
  font-size: 1.8rem;
  color: white;
  margin-bottom: 2rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Input = styled.input`
  padding: 1rem;
  border-radius: 10px;
  border: none;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 1rem;
  
  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const Select = styled.select`
  padding: 0.8rem;
  border-radius: 10px;
  border: none;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 1rem;
  appearance: none;
  cursor: pointer;
  margin-bottom: 1rem;
  text-align: left;
  padding-left: 1rem;
  
  /* Add a custom dropdown arrow */
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
  
  option {
    background-color: #1a1a1a;
    padding: 0.5rem;
  }
`;

const FileInput = styled.input`
  display: none;
`;

const FileLabel = styled.label`
  padding: 1rem;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  text-align: left;
`;

const SubmitButton = styled.button`
  padding: 1rem;
  border-radius: 50px;
  border: none;
  background-color: #FFD700;
  color: black;
  font-size: 1.2rem;
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.02);
  }
`;

const QuestionLabel = styled.label`
  color: white;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
`;

const AnswerInput = styled(Input)`
  margin-bottom: 1.5rem;
`;

const QuestionGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
`;


function ContactForm() {
    const [state, handleSubmit] = useForm("xbljeznb");
  
    return (
      <FormContainer>
        <Title>Get Your FREE</Title>
        <Subtitle>Character Demo</Subtitle>
        
        <Form onSubmit={handleSubmit}>
          <QuestionGroup>
            <QuestionLabel>What's your brand website?</QuestionLabel>
            <AnswerInput 
              type="text"
              name="brand_website"
              placeholder="Enter your website URL" 
              required 
            />
          </QuestionGroup>
  
          <QuestionGroup>
            <QuestionLabel>What's your email address?</QuestionLabel>
            <AnswerInput 
              type="email"
              name="email" 
              placeholder="Enter your email" 
              required 
            />
          </QuestionGroup>
  
          {/* <QuestionGroup>
            <QuestionLabel>Do you have an image for your brand ambassador?</QuestionLabel>
            <Select 
              name="has_image"
              defaultValue="no"
            >
              <option value="no">No</option>
              <option value="yes">Yes</option>
            </Select>
          </QuestionGroup>
  
          <QuestionGroup>
            <QuestionLabel>Do you have a voice for your brand ambassador?</QuestionLabel>
            <Select 
              name="has_voice"
              defaultValue="no"
            >
              <option value="no">No</option>
              <option value="yes">Yes</option>
            </Select>
          </QuestionGroup> */}
  
          <SubmitButton type="submit" disabled={state.submitting}>
            {state.submitting ? "Sending..." : "GET MY FREE DEMO"}
          </SubmitButton>
        </Form>
  
        {state.succeeded && (
          <div style={{ textAlign: 'center', marginTop: '2rem' }}>
            <h3>Thank you for your submission!</h3>
            <p>Your marketing will never the same.</p>
          </div>
        )}
      </FormContainer>
    );
  }
  
  export default ContactForm;