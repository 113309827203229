import styled from 'styled-components';
import { FaStar, FaUsers, FaUserFriends, FaGlobeAmericas, FaYoutube, FaLinkedin, FaInstagram, FaTiktok } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import { 
  logEvent, 
  logSocialClick, 
  logDownload, 
  logEmailClick, 
  logButtonClick 
} from '../utils/analytics';
import ComparisonTable from '../components/ComparisonTable';
import ContactForm from '../components/ContactForm';
import { ClientLogoSection } from '../pages/Team';




const PromoBanner = styled.div`
  background-color: #FF69B4;
  color: white;
  padding: 0.5rem 1rem;
  font-size: 0.7rem;
  text-align: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;

const HomeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: calc(100vh - 60px);
  margin-top: 120px;
  margin-bottom: 2rem;
  
  @media (max-width: 768px) {
    height: auto;
    margin-top: 80px;
    overflow-y: visible; // Allow content to extend beyond container
    position: static; // Remove any positioning constraints
  }
`;

const HeroSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6rem;
  max-width: 1400px;
  width: 100%;
  margin-top: 0;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1900px;
  padding-right: 1rem;
  gap: 10%;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
    padding-left: 2rem;
    align-items: flex-start;
    margin-top: 0;
    position: static;
    overflow: visible;
  }
`;

const TextColumn = styled.div`
  margin-left: 25rem;
  flex: 1.5;
  padding: 1rem;
  text-align: center;
  position: relative;

  @media (max-width: 768px) {
    padding: 0;
    margin-top: 2rem;
    margin-left: 0;
    text-align: left;
  }
`;
const VideoColumn = styled.div`
  flex: 1.5;
  padding: 1rem;
  margin-left: 5rem;
  margin-right: -5rem;

  @media (max-width: 768px) {
    order: 3;
    width: 100%;
    margin-top: 2rem;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 70vh;
  max-height: calc(100vh - 100px);
  border-radius: 10px;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 400px;
    margin: 2rem auto;
    width: 100%;
    max-width: 600px;
    
    iframe {
      width: 100% !important;
      height: 100% !important;
      position: relative !important;
      right: 0 !important;
      transform: none !important;
    }
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  margin: 1rem 0;
  text-align: center;
  position: relative;
  left: -35px;
  margin-top: 100px;
  @media (max-width: 768px) {
    font-size: 1.8rem;
    padding: 0 1rem;
    margin: 5rem 0 1rem;
    left: 0;
  }
`;

const Slogan = styled.h2`
  font-size: 2.2rem;
  margin: 1rem 0;
  text-align: center;
  position: relative;
  left: -85px;

  @media (max-width: 768px) {
    font-size: 1.8rem;
    padding: 0 1rem;
    margin: 1rem 0;
    left: 0;
  }
`;

const Description = styled.p`
  font-size: 2rem;
  margin: 1rem 0;
  line-height: 1.4;
  text-align: center;
  position: relative;
  left: -85px;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    padding: 0 1rem;
    margin: 1rem 0;
    left: 0;
  }
`;

const DownloadButton = styled.a`
  position: relative;
  left: -85px;
  padding: 1rem 2rem;
  font-size: 1.5rem;
  background-color: #FF69B4;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  color: white;
  display: inline-block;
  margin-bottom: 1rem;
  
  
  @media (max-width: 768px) {
    left: 0;
    padding: 0.8rem 1.6rem;
    font-size: 1.3rem;
    margin: 1rem;
  }
`;

const ReviewSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0;
`;

const Stars = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 0.5rem;
`;

const ReviewText = styled.span`
  font-size: 0.9rem;
  color: #E0E0E0;
  padding: 4px 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  left: -20px;
  gap: 3rem;
  margin: 2rem 0;

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    left: 0;
    padding: 0 1rem;
    gap: 1rem;
    margin: 2rem 0;
  }
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 768px) {
    flex: 1;
  }
`;

const StatIcon = styled.div`
  font-size: 3rem;
  color: #FF69B4;
  margin-bottom: 0.5rem;
`;

const StatNumber = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const StatLabel = styled.span`
  font-size: 1rem;
  text-align: center;
  max-width: 120px;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const AnimatedWord = styled.span`
  color: #FF69B4;
  transition: opacity 0.5s ease-in-out;
  font-size: 1.2em;
  border: 3px solid #FF69B4;
  padding: 0.2em 0.5em;
  border-radius: 4px;
  position: relative;
  top: 2px;
  bottom: 15px;
  @media (max-width: 768px) {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    display: inline-block;
    position: relative;
    top: 0;
  }
`;

const SocialSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0;
  position: relative;
  left: -85px;

  @media (max-width: 768px) {
    left: 0;
    margin: 2rem 0;
    align-items: flex-start; // Align items to the left on mobile
  }
`;

const SocialText = styled.p`
  font-size: 1.2rem;
  color: #E0E0E0;
  margin-bottom: 1rem;
`;

const SocialButtonsContainer = styled.div`
  display: flex;
  gap: 1.2rem;
  justify-content: center;
`;

const SocialButton = styled.a`
  background-color: rgba(255, 255, 255, 0.1);
  color: #FF69B4;
  padding: 1.2rem;
  margin-top: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    transform: scale(1.1);
    background-color: rgba(255, 105, 180, 0.2);
  }
`;

const ContactButton = styled.a`
  color: #FF69B4;
  font-size: 1.1rem;
  text-decoration: none;
  margin-top: 1.5rem;
  display: inline-block;
  padding: 0.8rem 1.5rem;
  border: 2px solid #FF69B4;
  border-radius: 50px;
  transition: all 0.3s ease;
  

  &:hover {
    transform: scale(1.05);
    background-color: rgba(255, 105, 180, 0.1);
  }
`;

const AnimatedArrow = styled.div`
  position: absolute;
  right: -130px;
  top: 40%;
  transform: translateY(-50%);
  font-size: 8rem;
  color: #FF69B4;
  animation: bounceRight 2s infinite;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 1rem;
    white-space: nowrap;
    color: #FF69B4;
    font-weight: bold;
    margin-top: -30px;
  }

  &:after {
    content: '30 sec explainer';
    font-size: 1rem;
    color: #FF69B4;
    position: absolute;
    bottom: 15px;
    white-space: nowrap;
  }

  @keyframes bounceRight {
    0%, 100% {
      transform: translateX(0) translateY(-50%);
      opacity: 1;
    }
    50% {
      transform: translateX(10px) translateY(-50%);
      opacity: 0.5;
    }
  }

  @media (max-width: 768px) {
    display: none; // Hide arrow on mobile
  }
`;

const FeatureList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 3rem auto;
  max-width: 800px;
  width: 100%;
  padding-left: 20%;  // This will push the content more to the left
`;

const FeatureItem = styled.div`
  font-size: 2rem;
  color: white;
  display: grid;
  grid-template-columns: 50px 1fr;  // Fixed width for emoji column
  align-items: center;
  gap: 1.5rem;
  
  .emoji {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.2rem;  // Slightly larger emojis
  }
  
  .text {
    text-align: left;
    line-height: 1.2;
  }
`;

const BusinessSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
  position: relative;
  left: -85px;

  @media (max-width: 768px) {
    left: 0;
    margin: 2rem 0;
    align-items: flex-start; // Align items to the left on mobile
  }
`;

const BusinessButton = styled.a`
  background-color: #FFD700;
  color: black;
  font-size: 2.3rem;
  padding: 1rem 2rem;
  border-radius: 50px;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;

  &:hover {
    background-color: #ffd900e6;
  }
`;

const StepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 7rem;
  width: 100%;
  padding: 2rem;
  position: relative;
  left: -85px;
  margin: 2rem 0;
  margin-left: 6rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start; // Change from center to flex-start
    gap: 2rem;
    left: 0;
    margin-left: 2rem; // Add smaller left margin on mobile
    padding: 1rem; // Reduce padding on mobile
  }
`;

const StepCard = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  transition: transform 0.3s ease;

  @media (max-width: 768px) {
    width: 90%; // Make cards slightly less than full width
    align-items: flex-start; // Align contents to the left
    text-align: left; // Align text to the left
    padding: 1.5rem; // Slightly reduce padding on mobile
  }

  &:hover {
    transform: translateY(-10px);
  }
`;

const StepTitle = styled.h3`
  color: #FF69B4;
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    text-align: left;
    margin-top: 0.5rem;
  }
`;

const StepDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  color: #E0E0E0;

  @media (max-width: 768px) {
    text-align: left; // Ensure description is left-aligned on mobile
  }
`;

const StepImage = styled.img`
  width: 200px;
  height: 200px;
  margin-bottom: 1.5rem;
  object-fit: contain;
`;

const FloatingAppButton = styled.a`
  background-color: #FF69B4;
  color: white;
  padding: 1rem 2rem;
  border-radius: 50px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  position: fixed;
  bottom: 28rem;
  right: 2rem;
  font-size: 1.2rem;
  transition: all 0.3s ease;

  &:hover {
    background-color: #ff69b4e6;
  }

  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    bottom: 1rem;
    right: 1rem;
    opacity: 0.8;
    transform: scale(0.8);
    
    // Add animation to make it appear/disappear
    animation: popIn 5s infinite;
    display: none; // Initially hidden
    
    &.show {
      display: flex;
    }
  }

  @keyframes popIn {
    0% { opacity: 0; transform: scale(0.8); }
    20% { opacity: 0.8; transform: scale(0.8); }
    80% { opacity: 0.8; transform: scale(0.8); }
    100% { opacity: 0; transform: scale(0.8); }
  }
`;

const AppIcon = styled.span`
  font-size: 1.5rem;
  position: relative;
  top: -2px;
`;

const NotificationPopup = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: rgba(0, 0, 0, 0.9);
  border: 2px solid #FF69B4;
  border-radius: 10px;
  padding: 15px 20px;
  color: white;
  font-size: 0.9rem;
  z-index: 1000;
  animation: slideIn 0.5s ease-out, fadeOut 0.5s ease-in 4.5s forwards;
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 300px;
  box-shadow: 0 4px 6px rgba(255, 105, 180, 0.1);

  @keyframes slideIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @media (max-width: 768px) {
    right: 10px;
    bottom: 10px;
    max-width: 250px;
    font-size: 0.8rem;
    padding: 12px 15px;
  }
`;

const NotificationIcon = styled.div`
  width: 40px;
  height: 40px;
  background-color: #FF69B4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
`;

const userNames = [
  "Emma", "James", "Sofia", "Liam", "Ava",
  "Noah", "Isabella", "Lucas", "Mia", "Oliver"
  // Add more names as needed
];

function YourNewComponent() {
  return (
    <>
      <StepsContainer>
        <StepCard>
          <StepTitle>Stick Your QR Code</StepTitle>
          <StepImage src="/qrcode/step1.png" alt="QR Code placement" />
          <StepDescription>
            Place your QR code prominently outside your premises (e.g., windows, doors, or signage). 
            Ensure it's visible and easy to scan for passersby.
          </StepDescription>
        </StepCard>

        <StepCard>
          <StepTitle>User Interaction</StepTitle>
          <StepImage src="/qrcode/step3.png" alt="Scanning QR" />
          <StepDescription>
            Customers scan the QR code using their smartphone. 
            Instantly connect them to an engaging Glimpse AR experience.
          </StepDescription>
        </StepCard>

        <StepCard>
          <StepTitle>Bring Your Brand to Life</StepTitle>
          <StepImage src="/qrcode/step2.png" alt="AR Experience" />
          <StepDescription>
            Your brand character or AR content pops up on their screen. 
            Deliver an unforgettable, interactive brand encounter.
          </StepDescription>
        </StepCard>
      </StepsContainer>
    </>
  );
}

function Home() {
  const [currentWord, setCurrentWord] = useState(0);
  const words = [
    { text: 'Lincoln', emoji: '🎩' },
    { text: 'virtual real-estate agent', emoji: '🏠' },
    { text: 'virtual waiter', emoji: '🍽' },
    { text: 'virtual tour guide', emoji: '🎫' }
  ];
  
  const [currentIdea, setCurrentIdea] = useState(0);
  const ideas = [
    { text: 'school', emoji: '🎓' },
    { text: 'restaurant', emoji: '🍴' },
    { text: 'museum', emoji: '🏛️' },
    { text: 'property', emoji: '🏠' },
    { text: 'hotel', emoji: '🏨' },
    { text: 'retail store', emoji: '🏪' },
    { text: 'art gallery', emoji: '🎨' },
    { text: 'tourist attraction', emoji: '🗺️' },
    { text: 'fitness center', emoji: '💪' },
    { text: 'coffee shop', emoji: '☕' },
    { text: 'theater', emoji: '🎭' },
    { text: 'winery', emoji: '🍷' }
  ];

  const [notification, setNotification] = useState(null);
  const [availableNames, setAvailableNames] = useState([...userNames]);
  const [showAppButton, setShowAppButton] = useState(false);

  useEffect(() => {
    const wordInterval = setInterval(() => {
      setCurrentWord((prev) => (prev + 1) % words.length);
    }, 3000);

    const ideaInterval = setInterval(() => {
      setCurrentIdea((prev) => (prev + 1) % ideas.length);
    }, 4000);

    const getRandomName = () => {
      if (availableNames.length === 0) {
        setAvailableNames([...userNames]);
        return userNames[Math.floor(Math.random() * userNames.length)];
      }
      
      const randomIndex = Math.floor(Math.random() * availableNames.length);
      const selectedName = availableNames[randomIndex];
      setAvailableNames(prev => prev.filter((_, index) => index !== randomIndex));
      return selectedName;
    };

    const showNotification = () => {
      const randomName = getRandomName();
      setNotification(randomName);
      setTimeout(() => setNotification(null), 5000);
    };

    const initialDelay = 10000;
    const firstTimer = setTimeout(showNotification, initialDelay);

    const intervalTimer = setInterval(() => {
      const delay = Math.floor(Math.random() * (360000 - 60000) + 60000);
      setTimeout(showNotification, delay);
    }, 360000);

    if (window.innerWidth <= 768) {
      // Show button every 30 seconds on mobile
      const showButton = () => {
        setShowAppButton(true);
        setTimeout(() => setShowAppButton(false), 5000);
      };

      const buttonInterval = setInterval(showButton, 30000);
      return () => clearInterval(buttonInterval);
    }

    return () => {
      clearInterval(wordInterval);
      clearInterval(ideaInterval);
      clearTimeout(firstTimer);
      clearInterval(intervalTimer);
    };
  }, []);

  const handleDownloadClick = () => {
    logEvent('Button', 'Download Click', 'App Store Download');
  };

  const handleSocialClick = (network) => {
    logEvent('Social Media', 'Click', network);
  };

  const handleEmailClick = () => {
    logEvent('Contact', 'Email Click', 'Contact Email Button');
  };

  const handleVideoClick = () => {
    logEvent('Video', 'Interaction', 'Demo Video Play');
  };

  const handleAnimatedWordClick = (word) => {
    logEvent('Navigation', 'Word Click', word);
  };

  const handleBusinessClick = () => {
    logEvent('Business', 'Calendar Click', 'Business Meeting Schedule');
  };

  return (
    <>
      <PromoBanner>
        🎄 25% OFF FOR THE MONTH OF DECEMBER! 🎄
      </PromoBanner>
    
      <Title>
        We've already built augmented reality interactive <AnimatedWord onClick={() => handleAnimatedWordClick(words[currentWord].text)}>
          {words[currentWord].text} {words[currentWord].emoji}
        </AnimatedWord>
        <span style={{ whiteSpace: 'nowrap', display: 'block', marginTop: '45px' }}></span>
      </Title>
      <Description>
        Interactive brand ambassador for your <AnimatedWord>
          {ideas[currentIdea].text} {ideas[currentIdea].emoji}
        </AnimatedWord>
      </Description>

      <HomeContainer>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <HeroSection>
            <ContentContainer>
              <TextColumn>
                <BusinessSection>
                  <ContactForm />
                </BusinessSection>
                <AnimatedArrow>→</AnimatedArrow>
              </TextColumn>
              
              <VideoColumn>
                <VideoWrapper onClick={handleVideoClick}>
                  <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/_JD3gw_hEt0"
                    title="Augmented Intelligent reality is here."
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    style={{ position: 'absolute', top: 0, left: 0 }}
                  />
                </VideoWrapper>
              </VideoColumn>  
            </ContentContainer>
          </HeroSection>
          
          <ClientLogoSection />
          <YourNewComponent />
          
          {notification && (
            <NotificationPopup>
              <NotificationIcon>👤</NotificationIcon>
              <div>
                <strong>{notification}</strong> just downloaded and tried Glimpse!
              </div>
            </NotificationPopup>
          )}
          
          <ComparisonTable />
          <div style={{ width: '80%', maxWidth: '1200px', margin: '4rem auto' }}>
            <ContactForm />
          </div>

          <SocialSection style={{ left: 0, margin: '4rem auto', alignItems: 'center' }}>
            <SocialButtonsContainer>
              <SocialButton 
                href="https://www.youtube.com/@Glimpse-l8k" 
                onClick={() => handleSocialClick('YouTube')}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <FaYoutube size={24} />
              </SocialButton>
              <SocialButton 
                href="https://www.linkedin.com/company/glimpsewiki/" 
                onClick={() => handleSocialClick('LinkedIn')}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <FaLinkedin size={24} />
              </SocialButton>
              <SocialButton 
                href="https://www.instagram.com/glimpse.stories/" 
                onClick={() => handleSocialClick('Instagram')}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <FaInstagram size={24} />
              </SocialButton>
              <SocialButton 
                href="https://www.tiktok.com/@glimpsewiki" 
                onClick={() => handleSocialClick('TikTok')}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <FaTiktok size={24} />
              </SocialButton>
            </SocialButtonsContainer>
            
            <ContactButton
              href="https://apps.apple.com/mt/app/glimpse-valletta/id6736998656"
              onClick={() => logDownload('iOS App')}
              target="_blank"
              rel="noopener noreferrer"
            >
              Try Our App
            </ContactButton>
          </SocialSection>
        </div>
      </HomeContainer>
      
      <FloatingAppButton 
        href="https://apps.apple.com/mt/app/glimpse-valletta/id6736998656"
        onClick={() => logDownload('iOS App')}
        target="_blank"
        rel="noopener noreferrer"
        className={showAppButton ? 'show' : ''}
      >
        <AppIcon>📱</AppIcon>
        Try Our App
      </FloatingAppButton>
    </>
  );
}

export default Home;