import { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logo from '../assets/output-onlinepngtools.png';

const NavbarContainer = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  height: 70px;
  z-index: 1000;
  background-color: #000000;
  margin-top: 25px;
`;

const Logo = styled.img`
  height: 70px;

  @media (max-width: 768px) {
    margin-left: -1rem;
  }
`;

// Desktop Menu
const DesktopMenu = styled.div`
  display: flex;
  gap: 2rem;
  
  @media (max-width: 768px) {
    display: none; // Hide on mobile
  }
`;

// Mobile Menu Container
const MobileMenu = styled.div`
  display: none; // Hide by default on desktop
  
  @media (max-width: 768px) {
    display: block; // Only show on mobile
  }
`;

const MenuIcon = styled.div`
  cursor: pointer;
  font-size: 1.5rem;
  color: #FFD700;
`;

const MobileMenuItems = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  top: 80px;
  right: 20px;
  background: rgba(0, 0, 0, 0.9);
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid #FFD700;
  gap: 1rem;
  z-index: 1000;
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: 2px solid #FF69B4;
  border-radius: 25px;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(255, 215, 0, 0.1);
    box-shadow: 0 0 10px rgba(255, 215, 0, 0.3);
  }
`;

const NavItem = styled.div`
  // Add your styles here
`;

// Add these new styled components after your existing styled components
const ProductDropdown = styled.div`
  margin-top: 0.1rem;
  position: relative;
  display: inline-block;
`;

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background: rgba(0, 0, 0, 0.9);
  min-width: 160px;
  border-radius: 10px;
  border: 1px solid #FFD700;
  padding: 0.5rem;
  z-index: 1001;

  ${ProductDropdown}:hover & {
    display: block;
  }
`;

const DropdownLink = styled(NavLink)`
  display: block;
  margin: 0.5rem 0;
  white-space: nowrap;
`;

// Add new styled component for mobile dropdown
const MobileProductDropdown = styled.div`
  position: relative;
  width: 100%;
`;

const MobileDropdownContent = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;a
  padding-left: 1rem;
`;

const MobileDropdownButton = styled.div`
  color: white;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border: 2px solid #FF69B4;
  border-radius: 25px;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(255, 215, 0, 0.1);
    box-shadow: 0 0 10px rgba(255, 215, 0, 0.3);
  }
`;

// Add this new styled component
const ProductsButton = styled.div`
  color: white;
  text-decoration: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: 2px solid #FF69B4;
  border-radius: 25px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 215, 0, 0.1);
    box-shadow: 0 0 10px rgba(255, 215, 0, 0.3);
  }
`;

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // Add new state for mobile products dropdown
  const [isProductsOpen, setIsProductsOpen] = useState(false);

  // Add this function to handle link clicks
  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <NavbarContainer>
      <Link to="/">
        <Logo src={logo} alt="Glimpse Logo" />
      </Link>
      
      {/* Desktop Menu */}
      <DesktopMenu>
        <ProductDropdown>
          <ProductsButton>Products</ProductsButton>
          <DropdownContent>
            <DropdownLink to="/products/travel">Glimpse Travel</DropdownLink>
            <DropdownLink to="/products/social">Glimpse Social</DropdownLink>
            <DropdownLink to="/products/business">Glimpse Business</DropdownLink>
          </DropdownContent>
        </ProductDropdown>
        <NavLink to="/team">Team</NavLink>
        <NavLink to="/blog">Blog</NavLink>
        <NavLink to="/ourwork">Our Characters</NavLink>
      </DesktopMenu>

      {/* Mobile Menu */}
      <MobileMenu>
        <MenuIcon onClick={() => setIsMenuOpen(!isMenuOpen)}>
          ☰
        </MenuIcon>
        <MobileMenuItems isOpen={isMenuOpen}>
          <MobileProductDropdown>
            <MobileDropdownButton onClick={() => setIsProductsOpen(!isProductsOpen)}>
              Products
            </MobileDropdownButton>
            <MobileDropdownContent isOpen={isProductsOpen}>
              <NavLink to="/products/travel" onClick={handleLinkClick}>Glimpse Travel</NavLink>
              <NavLink to="/products/social" onClick={handleLinkClick}>Glimpse Social</NavLink>
              <NavLink to="/products/business" onClick={handleLinkClick}>Glimpse Business</NavLink>
            </MobileDropdownContent>
          </MobileProductDropdown>
          <NavLink to="/team" onClick={handleLinkClick}>Team</NavLink>
          <NavLink to="/blog" onClick={handleLinkClick}>Blog</NavLink>
          <NavLink to="/ourwork" onClick={handleLinkClick}>Our Characters</NavLink>
        </MobileMenuItems>
      </MobileMenu>
    </NavbarContainer>
  );
}

export default Navbar;